import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
    CLEAN_ACTIONS,
    ELECTRICITY_PROOF_TYPE,
    GAS_PROOF_TYPE,
    WATER_PROOF_TYPE
} from '../configs/costants';
import {
    connectivtiyChangeOffline,
    decrementCounter,
    setCheckoutLoading,
    showSnackbar
} from './action-creators';

// TODO: rework the offline counter
export const networkIdMiddleware: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, IRootState>) =>
    (next) =>
    (action) => {
        const { type } = action;
        const useBeta = store.getState().user.user.response?.use_beta;

        const isPostAction =
            type.startsWith('POST_') && type.endsWith('_REQUEST');

        if (
            isPostAction ||
            type.startsWith('CREATE_LOCAL_') ||
            type === 'CREATE_MEDIA_REQUEST' ||
            type === 'CREATE_MEDIA_OBJECT' // added to get localId in the create_media_object action
        ) {
            const networkCounter = store.getState().appState.localCounter;
            const isSignatureMedia = Boolean(
                action?.params?.med_type && action?.params?.med_type === 'SIGN' // run decrement counter for signatures inside beta mode
            );
            // const isMetersMedia = Boolean(
            //     action?.params?.med_type &&
            //         (action?.params?.med_type === ELECTRICITY_PROOF_TYPE ||
            //             action?.params?.med_type === WATER_PROOF_TYPE ||
            //             action?.params?.med_type === GAS_PROOF_TYPE)
            // );
            const actionsAreAllowedToDecrementId = isSignatureMedia;
            if (
                (useBeta && type === 'CREATE_MEDIA_OBJECT') ||
                (useBeta &&
                    type === 'CREATE_MEDIA_REQUEST' &&
                    actionsAreAllowedToDecrementId) ||
                // (!useBeta && type === 'CREATE_MEDIA_REQUEST') ||
                !useBeta
            ) {
                store.dispatch(decrementCounter());
            }

            // if (useBeta && type === 'CREATE_MEDIA_OBJECT') {
            //     return next({ ...action, localId: networkCounter - 1 });
            // } else {
            //     return next({ ...action, localId: networkCounter });
            // }
            return next({ ...action, localId: networkCounter });
        }
        return next(action);
    };

//  handle FAIL actions in protocol
export const failedActionHandlerMiddleware: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, IRootState>) =>
    (next) =>
    (action) => {
        const { type } = action;

        const isInsideProtocol = window.location.pathname.includes('protocol');

        const isFailedAction = type.endsWith('_FAIL');
        const isChangeOfflineAction = type.includes(
            'CONNECTIVITY_CHANGE_OFFLINE'
        );
        const isChangeOnlineAction = type.includes(
            'CONNECTIVITY_CHANGE_ONLINE'
        );

        const useBeta = store.getState().user.user.response?.use_beta;

        const fromAllowedActions =
            isFailedAction || isChangeOfflineAction || isChangeOnlineAction;

        if (isInsideProtocol && fromAllowedActions) {
            return next({ ...action, useBeta: useBeta }); // attach useBeta if the action is an allowed action and inside beta mode
        } else {
            return next(action);
        }
    };

export const handleActionSideJobs: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, IRootState>) =>
    (next) =>
    (action) => {
        const { type } = action;

        const useBeta = store.getState().user.user.response?.use_beta;
        const offlineProtocolCurrentStep =
            store.getState().offlineProtocol.protocol.current_step;
        const offlineProtocolIsFinished =
            store.getState().offlineProtocol.protocolState.is_finished;
        // const isAsync = store.getState().offlineProtocol.protocolState.is_async;

        // write sidejobs for particular action by making a particular if-condition
        if (type === 'BACKGROUND_SYNC_FAIL') {
            useBeta &&
                store.dispatch(
                    showSnackbar({
                        message: 'async_protocol.media_upload_error'
                    })
                );
        }

        if (type === 'BACKGROUND_SYNC_SUCCESS') {
            // when betaMode, and not finishing protocol, switch to offline after backgroundSyncSuccess
            const offlineProtocolIsNotFinishing = !Boolean(
                offlineProtocolCurrentStep === 'confirm' &&
                    offlineProtocolIsFinished
            );
            if (useBeta && offlineProtocolIsNotFinishing) {
                store.dispatch(connectivtiyChangeOffline());
            }
        }
        if (type === 'GET_OFFLINE_PROTOCOL_CHECKOUT_ID_REQUEST') {
            // when betaMode, and not finishing protocol, switch to offline after backgroundSyncSuccess
            const isOutsideProtocol =
                !window.location.pathname.includes('protocol');
            if (isOutsideProtocol) {
                store.dispatch(setCheckoutLoading(true));
            }
        }

        return next(action);
    };

export const cleanActionMiddleware: Middleware =
    (store: MiddlewareAPI<Dispatch<AnyAction>, IRootState>) =>
    (next) =>
    (action) => {
        const { type } = action;

        const useBeta = store.getState().user.user.response?.use_beta;
        // const isInsideProtocol = window.location.pathname.includes('protocol');
        const actionShouldBeClean = CLEAN_ACTIONS.includes(type);

        if (useBeta && actionShouldBeClean) {
            const { __handler, ...cleanAction } = action;

            return next(cleanAction);
        } else {
            return next(action);
        }
    };
