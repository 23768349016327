import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import { isDevelopment } from '../../../utils';
import env from '../../../configs/environment';

interface IProps {
    account: IUser | null;
}

const Intercom: React.FC<IProps> = ({ account }) => {
    const [intercomLoaded, setIntercomLoaded] = useState(false);

    const onIntercomLoaded = useCallback(() => {
        (window as any).Intercom('boot', {
            app_id: env.getProperty('intercomAppId'),
            hide_default_launcher: true,
            custom_launcher_selector: '.custom_intercom_launcher'
        });

        setIntercomLoaded(true);
    }, []);

    const userId = account?.id;
    useEffect(() => {
        if (isDevelopment || !intercomLoaded || !userId) return;
        (window as any).Intercom('update', {
            user_id: userId
        });
    }, [intercomLoaded, userId]);

    useEffect(() => {
        if (isDevelopment) return;

        if (!(window as any).Intercom) {
            const s = document.createElement('script');
            s.async = true;
            s.type = 'text/javascript';
            s.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + "${env.getProperty(
                'intercomAppId'
            )}";var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
            const x = document.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
            onIntercomLoaded();
        } else {
            onIntercomLoaded();
        }
    }, [onIntercomLoaded]);

    return null;
};

const mapStateToProps = (state: IRootState) => ({
    account: state.user.user.response
});

export default connect(mapStateToProps)(Intercom);
