import React, {
    Suspense,
    lazy,
    useLayoutEffect,
    useState,
    useEffect
} from 'react';
import queryString from 'query-string';

import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { verifyAccount, logout, getBasicData } from './store/action-creators';
import { dispatchAsync } from './store/storeModule';

// Causes events not to fire?
import { Portal } from '@rmwc/base';

import SupportFab from './components/inventory/SupportFab';
import Intercom from './components/misc/Intercom';
import ScrollToTop from './components/misc/ScrollToTop';
import UserOfflineNotificationModal from './components/misc/UserOfflineNotificationModal';
import GlobalSnackbar from './components/misc/GlobalSnackbar';
import OfflineIndicator from './components/global/OfflineIndicator';
import UserPersonaSelectorModal from './components/global/UserPersonaSelectorModal';

import * as routes from './configs/routes';
import Loading from './components/atoms/Loading';

// Core routes to be loaded into main chunk
// TODO: rethink structure now that service worker caching is enabled

import NotFoundPage from './components/error/ErrorPage404';
import ServerErrorPage from './components/error/ErrorPage503';
import MagicLoginPage from './pages/MagicLoginPage';
import AcceptInvitePage from './pages/AcceptInvitePage';
import ProtocolTrackerPdfPage from './pages/ProtocolTrackerPdfPage';

import Login from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Routes from './pages/Routes';
import i18n from './i18next';
import i18next from './i18next';

// Optional chunks
const SharedIssue = lazy(
    () => import(/* webpackPrefetch: true */ './pages/SharedIssuePage')
);
const RegistrationPage = lazy(
    () => import(/* webpackPrefetch: true */ './pages/RegistrationPage')
);
const ResetPasswordConfirmPage = lazy(
    () => import(/* webpackPrefetch: true */ './pages/PasswordResetConfirmPage')
);
const ActivationSentPage = lazy(
    () => import(/* webpackPrefetch: true */ './pages/ActivationNotePage')
);
const PartnerProtocolPage = lazy(
    () => import(/* webpackPrefetch: true */ './pages/PartnerProtocolPage')
);

const App: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { search } = useLocation();
    const { confirmation_token, uid } = queryString.parse(search);

    const [confirming, setConfirming] = useState(false);

    const coreLoading = useSelector((state) => state.global.coreLoading);

    const [lang, setLang] = useState(i18n.language);

    i18n.on('languageChanged', () => {});

    i18next.on('languageChanged', (lng) => {
        setLang(lng);
    });

    useEffect(() => {
        dispatch(getBasicData(lang as ILang));
    }, [lang]);

    useLayoutEffect(() => {
        // Auto log in after an account has been verified
        if (typeof confirmation_token === 'string' && typeof uid === 'string') {
            setConfirming(true);
            dispatch(logout());

            dispatchAsync(dispatch, verifyAccount(uid, confirmation_token))
                .then(() =>
                    history.replace(routes.DASHBOARD_ROUTE, {
                        fromVerification: true
                    })
                )
                .finally(() => setConfirming(false));

            // clear tokens
            history.push({ search: '' });
        }
    }, [dispatch, history, uid, confirmation_token]);

    const loadingNode = <Loading className="loading__page" size="xlarge" />;

    if (coreLoading || confirming) return loadingNode;
    return (
        <Suspense fallback={loadingNode}>
            <OfflineIndicator />
            <Switch>
                <Route exact path={routes.ROOT_PATH} component={Login} />
                <Route
                    exact
                    path={routes.PARTNER_PROTOCOL_PATH}
                    component={PartnerProtocolPage}
                />
                <Route
                    exact
                    path={routes.SHARED_ISSUE_PATH}
                    component={SharedIssue}
                />
                <Route
                    exact
                    path={routes.REGISTRATION_PATH}
                    component={RegistrationPage}
                />
                <Route
                    exact
                    path={routes.ACTIVATION_PATH}
                    component={ActivationSentPage}
                />
                <Route
                    exact
                    path={routes.PASSWORD_RESET_PATH}
                    component={ResetPasswordPage}
                />
                <Route
                    exact
                    path={routes.RESET_CONFIRMATION_PATH}
                    component={ResetPasswordConfirmPage}
                />
                <Route
                    exact
                    path={routes.MAGIC_LOGIN_PATH}
                    component={MagicLoginPage}
                />
                <Route
                    exact
                    path={routes.ACCEPT_INVITE_PATH}
                    component={AcceptInvitePage}
                />
                <Route
                    exact
                    path={routes.NOT_FOUND_ERROR_PATH}
                    component={NotFoundPage}
                />
                <Route
                    exact
                    path={routes.SERVER_ERROR_PATH}
                    component={ServerErrorPage}
                />
                <Route
                    exact
                    path={routes.PROTOCOL_TRACKER_PATH}
                    component={ProtocolTrackerPdfPage}
                />
                <Route path={routes.ROOT_PATH} component={Routes} />
                <Redirect to={routes.ROOT_ROUTE} />
            </Switch>
            <UserPersonaSelectorModal />
            <GlobalSnackbar />
            <Portal />
            <SupportFab />
            <Intercom />
            <ScrollToTop />
            <UserOfflineNotificationModal />
        </Suspense>
    );
};

export default App;
