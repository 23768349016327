import { all } from 'redux-saga/effects';
import SyncOfflineProtocol from './SyncOfflineProtocol';
import SyncOfflineProtocolPreview from './SyncOfflineProtocolPreview';
import offlineProtocolPostSignature from './postSignature';
import checkOfflinePdf from './checkOfflinePdf';
import postOfflineProtocolCheckoutStatus from './postOfflineProtocolCheckoutStatus';
import getOfflineProtocolCheckoutId from './getOfflineProtocolCheckoutId';

export default all([
    getOfflineProtocolCheckoutId,
    SyncOfflineProtocol,
    SyncOfflineProtocolPreview,
    offlineProtocolPostSignature,
    checkOfflinePdf,
    postOfflineProtocolCheckoutStatus
]);
