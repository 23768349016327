import { useHistory } from 'react-router';
import { TFunction } from 'i18next';
import { Dispatch } from 'redux';

import { PROTOCOL_ROUTE } from '../configs/routes';

import {
    patchIssue,
    patchProtocolIssue,
    postPrivateLog,
    createIssueLink,
    showSnackbar,
    deleteIssue,
    deleteProtocolIssue,
    postProtocol,
    deleteOfflineProtocolIssue,
    finishProtocol,
    getOfflineProtocolCheckoutId,
    assignOfflineProtocolSessionId,
    setCheckoutLoading,
} from '../store/action-creators';

import { dispatchAsync } from '../store/storeModule';
import { getFullname } from '../modules/account';
import i18n from '../i18next';

interface IRequestParams {
    dispatch: Dispatch;
    onSuccess?: ICallback;
}

interface IIssueShareParams extends IRequestParams {
    t: TFunction;
    id?: number;
    user: IUser | null;
}

export const handleShareIssue = (params: IIssueShareParams) => {
    const { id, user, t, dispatch, onSuccess } = params;
    return dispatchAsync(dispatch, createIssueLink(Number(id)))
        .then((_) => {
            if (user) {
                const name = getFullname(user.firstname, user.lastname);

                const message = `${t('issue_logs.shared_by')} ${name}`;
                const logLiteral = {
                    sll_iss_id: id,
                    sll_logmsg: message,
                    sll_public: 1
                };

                dispatch(postPrivateLog(logLiteral));
            }
            onSuccess?.();
        })
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'share_issue_fail_message', error })
            )
        );
};

interface IIssueFixParams extends IRequestParams {
    id?: number;
    isOffline?: boolean;
}

export const handleFixIssue = (params: IIssueFixParams) => {
    const { id, dispatch, onSuccess } = params;
    const lang = i18n.language as ILang;
    dispatchAsync(dispatch, patchIssue(Number(id), { iss_fix: true }, lang))
        .then(onSuccess)
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'mark_as_fixed_fail_message', error })
            )
        );
};
export const handleProtocolFixIssue = (params: IIssueFixParams) => {
    const { id, dispatch, onSuccess, isOffline = false } = params;
    const lang = i18n.language as ILang;
    dispatchAsync(dispatch, patchProtocolIssue(Number(id), { fix: true }, lang, isOffline))
        .then(onSuccess)
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'mark_as_fixed_fail_message', error })
            )
        );
};

interface IIssueDeleteParams extends IRequestParams {
    id?: number;
    isOffline?: boolean
}
interface IBetaIssueDeleteParams extends IRequestParams {
    issueId?: number;
    isOffline?: boolean;
    elementId?: number;
    roomId?: number;
    itemId?: number;
}

export const handleDeleteIssue = (params: IIssueDeleteParams) => {
    const { id, dispatch, onSuccess, isOffline = false } = params;
    dispatchAsync(dispatch, deleteIssue(Number(id)))
        .then(onSuccess)
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'mark_as_fixed_fail_message', error })
            )
        );
};

export const handleProtocolDeleteIssue = (params: IIssueDeleteParams) => { 
    const { id, dispatch, onSuccess, isOffline = false } = params;
    dispatchAsync(dispatch, deleteProtocolIssue(Number(id), Boolean(isOffline)))
        .then(onSuccess)
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'mark_as_fixed_fail_message', error })
            )
        );
};

export const handleBetaProtocolDeleteIssue = (params: IBetaIssueDeleteParams) => { 
    const { issueId, dispatch, onSuccess, isOffline = false, elementId = null, roomId = null, itemId = null } = params;
    dispatchAsync(dispatch, deleteOfflineProtocolIssue({issueId: Number(issueId), isOffline: Boolean(isOffline), roomId, elementId, itemId}))
        .then(onSuccess)
        .catch(({ error }) =>
            dispatch(
                showSnackbar({ message: 'mark_as_fixed_fail_message', error })
            )
        );
};

interface IDpdParams extends IRequestParams {
    dpdId: number;
    history: RT<typeof useHistory>;
    useBeta?: boolean;
    offlineProtocolSessionId: null | string
}

export const handleStartProtocol = ({
    dispatch,
    dpdId,
    history,
    useBeta,
    offlineProtocolSessionId
}: IDpdParams) => {
    return dispatchAsync(dispatch, postProtocol({ dpd_id: dpdId }))
        .then((action: any) => {
            const id: number | undefined = action.payload.prt_id;

            // if (id != null) history.push({ pathname: PROTOCOL_ROUTE(id) });
            if (id != null) { // temporary replacement, results in too much time to load protocol
                    useBeta
                    ? dispatchAsync(dispatch, getOfflineProtocolCheckoutId(id, { session_id: offlineProtocolSessionId }))
                        .then((_) => {
                            dispatch(setCheckoutLoading(false));
                            history.push({ pathname: PROTOCOL_ROUTE(id) })})
                        .catch(({ error }) => dispatch(showSnackbar({ message: 'start_protocol_error', error })))
                    : history.push({ pathname: PROTOCOL_ROUTE(id) });
            }
            return id;
        })
        .catch(({ error }) =>
            dispatch(showSnackbar({ message: 'start_protocol_error', error }))
        );
};

interface IDpdAdParams extends IRequestParams {
    dpdId: number;
    history: RT<typeof useHistory>;
}
