import React, { useState } from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { handleProtocolDownload, matchSimple } from '../../../utils';

import { LinearProgress } from '@rmwc/linear-progress';
import StdButton from '../../atoms/StdButton';

import './offline-bar.scss';
import { useDispatch, useSelector } from 'react-redux';
import { dispatchAsync } from '../../../store/storeModule';
import {
    clearProtocolSave,
    getOfflineProtocolCheckoutId,
    getProtocol,
    getProtocolForLatestData,
    offlineProtocolReadyToCheckin,
    postOfflineProtocolCheckinStatus,
    runProtocolSave,
    savingOfflineProtocolData,
    showSnackbar
} from '../../../store/action-creators';
import {
    checkConnection,
    handleOnlineSwitchForProtocolCheckin
} from '../../../modules/serviceWorker';
import { useHistory } from 'react-router';
import { MenuItem, MenuSurfaceAnchor } from '@rmwc/menu';
import StdDropdown from '../../atoms/StdDropdown';
import StdIcon from '../../atoms/StdIcon';
import { DASHBOARD_PATH } from '../../../configs/routes';
import { backDateToFrontWithTime } from '../../../utils/formatter';
import ProtocolDownloadConfirmationModal from '../../protocol/ProtocolDownloadConfirmationModal';
// import { useEffect } from 'react';

interface IProps {
    status: ISyncStatus;
    filesize?: number;
    time?: number;
    isOffline?: boolean;
    isManualOffline?: boolean;
    isUnsupported?: boolean;
    onSwitchOnline?: ICallback;
    onRetrySync?: ICallback;
    onDiscardData?: ICallback;
}

const OfflineBar: React.FC<IProps> = (props) => {
    const {
        filesize = 0,
        time,
        isOffline,
        isManualOffline,
        status,
        isUnsupported,
        onSwitchOnline,
        onRetrySync,
        onDiscardData
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [openSaveProtocol, setOpenSaveProtocol] = useState<Boolean | boolean>(
        false
    );
    const [openDownloadConfirmationModal, setOpenDownloadConfirmationModal] =
        useState<boolean>(false);
    const isSyncing = status.status === 'syncing';
    const hasFailed = status.status === 'sync-fail';
    const hasSucceded = status.status === 'sync-success';
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const offlineProtocolLastCheckinTime = useSelector(
        (state) => state.offlineProtocol.protocolState.lastCheckinTime
    );
    const offlineProtocolIsSaving = useSelector(
        (state) => state.offlineProtocol.protocolState.saving_protocol_data
    );
    const savingProtocolFailed = useSelector(
        (state) =>
            state.offlineProtocol.protocolState?.protocolOfflineSave?.failed
    );
    const protocolSavedAndCompleted = useSelector(
        (state) => state.offlineProtocol.protocolState?.is_completed
    );
    const betaProtocol = useSelector((state) => state.offlineProtocol.protocol);
    const protocolMedia = useSelector(
        (state) => state.offlineProtocol.protocolMedia
    );
    const appMedia = useSelector((state) => state.uploads.uploads.byIds);
    const offlineProtocolSessionId = useSelector(
        (state) => state.global.offlineProtocolSessionId
    );

    const isSuccess = !isManualOffline && (isSyncing || hasSucceded);

    const isInsideProtocol = Boolean(
        window.location.pathname.includes('protocol')
    );

    const containerClass = clsx(
        'offline-bar__container',
        isSuccess && 'offline-bar__container--success',
        useBeta &&
            'offline-bar__container--async offline-bar__indication-height',
        useBeta &&
            savingProtocolFailed &&
            'offline-bar__container--async-danger  offline-bar__indication-height',
        useBeta &&
            protocolSavedAndCompleted &&
            'offline-bar__container--async-success  offline-bar__indication-height' // will handle protocol sync success case later after completion
    );

    const timeEstimation =
        time != null && time > 1
            ? t('offline.time_estimation', { time })
            : t('offline.time_estimation_short');

    const handleLocalProtocolDownload = () => {
        const protocolMediaArray = Object.entries(protocolMedia) as [
            string,
            any
        ][];
        const attendeeSigIds = betaProtocol.attendees.map(
            (attendee: IAttendeeBase) => attendee.signature_media_id
        );
        const allProtocolMedia = Object.entries(appMedia) as [string, any][];

        const updatedMediaArray = protocolMediaArray.filter(
            ([_, mediaObject]) =>
                Boolean(
                    mediaObject?.type === 'BI' ||
                        mediaObject?.protocol_room ||
                        mediaObject?.protocol_element ||
                        mediaObject?.protocol_item
                )
        );

        allProtocolMedia.forEach((mediaEntry) => {
            const [mediaId, _] = mediaEntry;
            if (attendeeSigIds.includes(JSON.parse(mediaId))) {
                updatedMediaArray.push(mediaEntry);
            }
        });

        handleProtocolDownload(betaProtocol, updatedMediaArray);
    };

    const message = matchSimple()
        .on(
            Boolean(useBeta && isInsideProtocol && protocolSavedAndCompleted),
            <div className="offline-bar__content">
                <span className="inline-m">{t('offline.back_online')}</span>
                {/* <span>
                    <span className="inline-s">
                        {t('offline.saving_data', { amount: filesize })}
                    </span>
                    <span>{timeEstimation}</span>
                </span> */}
            </div>
        )
        .on(
            Boolean(useBeta && isInsideProtocol && savingProtocolFailed),
            <>
                <div className="offline-bar__content">
                    <div>
                        <div>
                            This protocol has been exclusively checked-out to
                            another device. Use 'Save' button to tackle the
                            conflict.
                        </div>
                    </div>
                    <div className="offline-bar__offline-danger-actions">
                        {isInsideProtocol && (
                            <>
                                <StdButton
                                    className="offline-bar__async-mode-switch"
                                    type="secondary"
                                    onClick={() => {
                                        dispatchAsync(
                                            dispatch,
                                            getOfflineProtocolCheckoutId(
                                                betaProtocol.pk,
                                                {
                                                    override: true,
                                                    session_id:
                                                        offlineProtocolSessionId
                                                }
                                                // true
                                            )
                                        ).then(() =>
                                            dispatch(clearProtocolSave())
                                        );
                                    }}
                                >
                                    Save Protocol
                                </StdButton>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
        .on(
            Boolean(useBeta && !savingProtocolFailed && isInsideProtocol),
            <>
                <div className="offline-bar__content">
                    <div>
                        <div>
                            This protocol is exclusively checked-out to this
                            device. The data are not uploaded to the AIMMO
                            servers unless you click 'Save protocol' button! Do
                            not close Aimmo until the protocol has been saved!
                        </div>
                        {offlineProtocolLastCheckinTime && (
                            <div className="offline-bar__content__checkinTime">
                                Last Saved:{' '}
                                {backDateToFrontWithTime(
                                    offlineProtocolLastCheckinTime
                                )}
                            </div>
                        )}
                    </div>
                    <div className="offline-bar__offline-actions">
                        {isInsideProtocol && (
                            <>
                                <StdButton
                                    className="offline-bar__async-mode-switch"
                                    type="secondary"
                                    onClick={() => {
                                        dispatch(
                                            runProtocolSave({
                                                openCheckoutWarningOnFail: true
                                            })
                                        );
                                    }}
                                >
                                    Save Protocol
                                </StdButton>
                                <MenuSurfaceAnchor>
                                    <StdDropdown
                                        anchorCorner="bottomStart"
                                        open={openSaveProtocol as boolean}
                                        onClose={() =>
                                            setOpenSaveProtocol(false)
                                        }
                                        onSelect={(e) => {
                                            const {
                                                detail: { item }
                                            } = e;
                                            const value = item.dataset['value'];
                                            switch (value) {
                                                case 'save_and_exit':
                                                    dispatch(
                                                        runProtocolSave({
                                                            exitPath:
                                                                DASHBOARD_PATH,
                                                            willExit: true,
                                                            openCheckoutWarningOnFail:
                                                                true
                                                        })
                                                    );
                                                    break;
                                                case 'download_protocol':
                                                    setOpenDownloadConfirmationModal(
                                                        true
                                                    );
                                                    break;
                                            }
                                        }}
                                    >
                                        <MenuItem data-value="save_and_exit">
                                            Save and Exit
                                        </MenuItem>
                                        <MenuItem data-value="download_protocol">
                                            {t(
                                                'async.offline_protocol_download_protocol'
                                            )}
                                        </MenuItem>
                                    </StdDropdown>
                                    <StdButton
                                        className="offline-bar__async-mode-switch"
                                        type="secondary"
                                        onClick={() => {
                                            setOpenSaveProtocol(true);
                                        }}
                                    >
                                        <StdIcon name="chevron-down" />
                                    </StdButton>
                                </MenuSurfaceAnchor>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
        .on(
            isSyncing,
            <div className="offline-bar__content">
                <span className="inline-m">{t('offline.back_online')}</span>
                <span>
                    <span className="inline-s">
                        {t('offline.saving_data', { amount: filesize })}
                    </span>
                    <span>{timeEstimation}</span>
                </span>
            </div>
        )
        .on(
            Boolean(isManualOffline && !isUnsupported),
            <div className="offline-bar__content">
                <div className="offline-bar__text">
                    <div>{t('offline.offline_mode_title').toUpperCase()}</div>
                    <div>{t('offline_mode.in_offline_mode_message')}</div>
                </div>
                {onSwitchOnline && (
                    <StdButton type="primary" onClick={onSwitchOnline}>
                        {t('protocol.go_online')}
                    </StdButton>
                )}
            </div>
        )
        .on(
            hasSucceded,
            <div className="offline-bar__content">
                <span className="inline-m">{t('offline.back_online')}</span>
                <div>{t('offline.sync_success')}</div>
            </div>
        )
        .on(
            hasFailed,
            <div className="offline-bar__content">
                <div className="offline-bar__text">
                    <div>{t('offline.sync_fail_title').toUpperCase()}</div>
                    <div>{t('offline.sync_fail_message')}</div>
                </div>
                <div className="offline-bar__actions">
                    {onRetrySync && (
                        <StdButton
                            type="dark"
                            outline="white"
                            onClick={onRetrySync}
                        >
                            {t('offline.retry_sync')}
                        </StdButton>
                    )}
                    {onDiscardData && (
                        <StdButton
                            type="dark"
                            outline="white"
                            onClick={onDiscardData}
                        >
                            {t('offline.discard_data')}
                        </StdButton>
                    )}
                </div>
            </div>
        )
        .on(
            Boolean(isUnsupported),
            <div className="offline-bar__content">
                <div>{t('offline_mode.unsupported_page_message')}</div>
                {isManualOffline && onSwitchOnline && (
                    <StdButton type="primary" onClick={onSwitchOnline}>
                        {t('protocol.go_online')}
                    </StdButton>
                )}
            </div>
        )
        .on(
            Boolean(isOffline),
            <div>{t('offline.you_are_offline_message')}</div>
        )
        .otherwise(<div>{t('offline.you_are_online_message')}</div>);

    return (
        <>
            <div className={`${useBeta ? 'custom-progress--wrapper' : ''}`}>
                <div className={containerClass}>{message}</div>
                {!useBeta && isSyncing && (
                    <LinearProgress className="custom-progress--greeen" />
                )}
                {(useBeta && isSyncing) ||
                    (offlineProtocolIsSaving && (
                        <LinearProgress className="custom-progress--blue" />
                    ))}
            </div>
            <ProtocolDownloadConfirmationModal
                open={openDownloadConfirmationModal}
                onAccept={() => {
                    handleLocalProtocolDownload();
                    setOpenDownloadConfirmationModal(false);
                }}
                onCancel={() => {
                    setOpenDownloadConfirmationModal(false);
                }}
            />
        </>
    );
};

export default OfflineBar;
