import { SagaIterator } from '@redux-saga/types';
import { AnyAction } from 'redux';
import {
    call,
    put,
    cancelled,
    take,
    takeEvery,
    race,
    all,
    fork,
    select,
    actionChannel
} from 'redux-saga/effects';

import axios, { AxiosRequestConfig } from 'axios';
import { isSafari } from 'react-device-detect';

import {
    uploadMedia,
    uploadMediaSuccess,
    uploadMediaFail,
    postMedia,
    postMediaSuccess,
    postMediaFail,
    deleteMedia,
    onUploadMediaProgress,
    onUploadMediaRequest,
    onUploadMediaFail,
    onUploadMediaSuccess,
    onUploadMediaCancel,
    setUploadPreview,
    setUploadThumbnailPreview,
    switchModeAsynchronous,
    createMediaObject
} from '../../action-creators';

import * as actions from '../../actions/appState';

import {
    getClientId,
    getOfflineStatus,
    getOfflineModeStatus
} from '../../selectors/user';

import { urlFieldsToFormData, fileParamsToFormData } from '../modules/formats';
import { getHeader, callWithRefresh } from '../modules/request';
import {
    disableLoading,
    getAsyncActName,
    getCreatorType,
    skipActionFactory
} from '../../storeModule';

import { isIOS, isServiceWorkerRunning } from '../../../utils';
import { getActionId, isActionIncluded } from '../../storeModule';
import { generateThumbnail, scaleImageFromFile } from '../../../modules/image';
import { fileToBase64 } from '../../../modules/file';

import { workerHeaders } from '../../../modules/serviceWorker';
import { isNetworkDownError } from '../../../modules/error';

// Import the root store to dispatch actions in events
import { store } from '../..';
import compressUploader from '../../../utils/compressUploader';

/*
  Saga that handles creating Media table entries
  and uploading files once presigned aws url is returned.

  Handles offline mode by creating base64 thumbnail for image or video.
*/

function fileToBase64Fallback(file: File, params: object) {
    return fileToBase64(file).then((data) => {
        return {
            ...params,
            med_path: data,
            med_filename: file.name
        };
    });
}

async function generateVideoBase64(file: File) {
    const baseVideo = await fileToBase64(file).then((data) => data);
    return baseVideo;
}

function calculateProgress(e: ProgressEvent<EventTarget>) {
    return Math.floor((e.loaded / e.total) * 100);
}

type ICreateMediaAction = RT<typeof postMedia>;
type IUploadAction = RT<typeof uploadMedia>;

function* postMediaRequest(action: ICreateMediaAction): SagaIterator {
    const useBeta = yield select(
        (state: IRootState) => state.user.user.response?.use_beta
    );
    // const metadata = { localId: getActionId(action) };
    const metadata = {
        localId: useBeta
            ? (action?.params?.localId as number) || getActionId(action)
            : getActionId(action)
    };
    //
    // console.log(
    //     'this is the Post Media request for CREATE_MEDIA_REQUEST --> ',
    //     { action, metadata }
    // );
    // const mediaCompressor = useCompressUploader();

    const clinetId = yield select(getClientId);
    const isManualOffline = yield select(getOfflineModeStatus);
    const isOffline = yield select(getOfflineStatus);

    const isOfflineMode = isManualOffline && isServiceWorkerRunning();

    // TODO: distinqish between skip & abort
    // Skip - request being skipped for now that the user is offline
    // Abort - request is cancelled by the user
    const skipAction = skipActionFactory(
        getAsyncActName(action.type),
        metadata
    );
    try {
        // if we're offline, but are not in offline mode, ignore request
        if (isOffline && !isManualOffline && isServiceWorkerRunning()) {
            throw Error('Network Error');
        }

        const { file, params } = action;

        const isWebkitFallback = isOfflineMode && (isIOS || isSafari);

        // Webkit seems not support service worker 'fetch' event for formData requests
        const requestData = isWebkitFallback
            ? yield call(fileToBase64Fallback, file, params)
            : fileParamsToFormData(file, file.name, params);
        console.log('requestData 133', requestData);

        // if offline mode is active, include worker headers
        const customHeader = isOfflineMode
            ? workerHeaders(
                  clinetId,
                  metadata.localId,
                  getCreatorType(postMediaSuccess),
                  getCreatorType(postMediaFail)
              )
            : undefined;
        console.log('customHeader 144', customHeader);

        const transformOfflineHeaders = workerHeaders(
            clinetId,
            metadata.localId,
            getCreatorType(postMediaSuccess),
            getCreatorType(postMediaFail)
        );
        console.log('transformOfflineHeaders 152', transformOfflineHeaders);

        const requestConfig = {
            method: 'POST',
            requestUrl: 'media/media_create/',
            customHeader,
            transformOfflineHeaders,
            requestData
        };

        const response = yield call(callWithRefresh as any, requestConfig);
        const data: ICreateMediaResult = response.data;

        const { success, url, url_fields, ...media } = data;

        const successAction = {
            ...postMediaSuccess({ media, result: data }),
            ...metadata
        };
        console.log('THIS IS SUCCESS ACTION FOR CREATE_MEDIA ---> ', {
            successAction
        });
        yield put(successAction);

        return data;
    } catch (error) {
        console.log('error 178 ---> ', { error });
        const includesFailedHeader =
            error.config.headers['failed-api'] === 'twice';
        const avoidError = isOfflineMode && isNetworkDownError(error as Error);
        if (avoidError || includesFailedHeader) {
            yield put(skipAction);
            return;
        }
        const errorAction = { ...postMediaFail(error as Error), ...metadata };
        yield put(errorAction);
    } finally {
        if (yield cancelled()) {
            yield put(skipAction);
        }
    }
}

function* uploadMediaRequest(
    file: File,
    awsData: ICreateMediaResult
): SagaIterator {
    const cancelSource = axios.CancelToken.source();
    const id = awsData.med_id;

    try {
        const formData = urlFieldsToFormData(file, awsData.url_fields);
        const header: AxiosRequestConfig = yield call(
            getHeader,
            'POST',
            formData,
            false
        );

        const onUploadProgress = (event: ProgressEvent<EventTarget>) => {
            // Note: using store directly is not semantic
            // use eventChannel for upload progress insteads
            store.dispatch(onUploadMediaProgress(id, calculateProgress(event)));
        };

        yield call(axios, awsData.url, {
            ...header,
            onUploadProgress,
            cancelToken: cancelSource.token
        });

        yield put(onUploadMediaSuccess(id));
    } catch (error) {
        yield put(onUploadMediaFail(id)); // add error
        // yield put(switchModeAsynchronous()); // turn protocol to asynchronous mode on error
        throw error;
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel);
            yield put(onUploadMediaCancel(id));
        }
    }
}

// Do media uploads one by one not to exceed AWS upload limit
function* queueMediaUpload(): SagaIterator {
    const queue = yield actionChannel('ON_MEDIA_UPLOAD_REQUEST');
    while (true) {
        const action = yield take(queue);
        const mediaId = action.awsData.med_id;

        yield race({
            upload: call(uploadMediaRequest, action.file, action.awsData),
            cancel: take((action: AnyAction) => {
                if (action.type !== actions.CANCEL_MEDIA_UPLOAD) return false;

                return action.id == null || action.id === mediaId;
            })
        });
    }
}

function* generatePreviews(file: File, id: number): SagaIterator {
    const filetype = file.type;
    const isImage = filetype.startsWith('image');
    const isVideo = filetype.startsWith('video');

    // Base64 is quite big, and there is a chance of media being stuck in local storage
    // TODO: implement expiry based garbage collection for entities (media first) for storage
    // UPD1: Media done

    // No point doing object url for videos/images as page reload will clear them
    if (isImage) {
        // Used base64 to keep previews on reload
        try {
            const imageURL = yield call(scaleImageFromFile, file);
            yield put(setUploadPreview(id, imageURL));
        } catch (error) {
            console.error('Failed to generate preview for image: ', error);
        }
    }

    if (isVideo) {
        const videoURL = URL.createObjectURL(file);
        const videoBase = yield call(generateVideoBase64, file);
        try {
            const thumbnailUrl = yield call(generateThumbnail, videoURL);
            yield put(setUploadThumbnailPreview(id, thumbnailUrl, videoBase));
        } catch (error) {
            console.error('Failed to generate video thumbnail: ', error);
        } finally {
            URL.revokeObjectURL(videoURL);
        }
    }
}

function* uploadMediaTransaction(action: IUploadAction): SagaIterator {
    const { resolve, reject } = (action as any).__handler || {};
    const uploadParams = action.params;

    const useBeta = yield select(
        (state: IRootState) => state.user.user.response?.use_beta
    );
    const isBetaOffline = yield select(
        (state: IRootState) => state.global.isBetaOffline
    );

    const { compressAndUpload } = compressUploader(useBeta, isBetaOffline);

    const isManualOffline = yield select(
        (state: IRootState) => state.appState.isManualOffline
    );

    const isInsideProtocol = window.location.pathname.includes('protocol');

    const isNotSignatureMedia = !Boolean(
        uploadParams.params.med_type && uploadParams.params.med_type === 'SIGN'
    );

    const shouldRunCompression =
        useBeta && isInsideProtocol && isNotSignatureMedia;

    const uploadedFile = uploadParams.file;
    const compressedAction =
        shouldRunCompression &&
        // isNotSignatureMedia &&
        (yield put(
            createMediaObject(uploadedFile, {
                ...uploadParams.params,
                useBeta: Number(useBeta)
            })
        )); // running preview creation with original media

    const compressedFile = shouldRunCompression
        ? yield call(compressAndUpload, uploadParams.file)
        : uploadedFile;
    const originalAction = yield put(
        // postMedia(uploadedFile, {...uploadParams.params, useBeta: Number(useBeta)})
        postMedia(compressedFile, {
            ...uploadParams.params,
            ...(shouldRunCompression && {
                localId:
                    // shouldRunCompression ?
                    compressedAction?.params?.localId ||
                    getActionId(compressedAction)
                // : getActionId(action)
            })
        })
    );
    const metadata = {
        localId: shouldRunCompression
            ? compressedAction?.params?.localId || getActionId(compressedAction)
            : getActionId(originalAction)
    };
    try {
        // if (isManualOffline && isServiceWorkerRunning()) {
        if (isServiceWorkerRunning()) {
            // yield fork(generatePreviews, uploadedFile, metadata.localId);
            yield fork(generatePreviews, compressedFile, metadata.localId);
        }

        const postResultAction = yield take((matchedAction: AnyAction) => {
            if (
                !isActionIncluded(matchedAction, [
                    actions.CREATE_MEDIA_SUCCESS,
                    actions.CREATE_MEDIA_FAIL,
                    'CREATE_MEDIA_SKIP'
                ])
            ) {
                return false;
            }
            return getActionId(originalAction) === getActionId(matchedAction);
        });

        if (postResultAction.type === actions.CREATE_MEDIA_FAIL) {
            throw postResultAction.error;
        }

        const skipAction = skipActionFactory(
            getAsyncActName(action.type),
            metadata
        );

        if (postResultAction.type === 'CREATE_MEDIA_SKIP') {
            yield put(skipAction);
            reject?.(skipAction);

            return;
        }

        const result: ICreateMediaResult = postResultAction.payload.result;
        const newId: number = result.med_id;

        uploadParams.onMediaCreated?.(metadata.localId, newId); // Callbacks are not ideal

        // yield put(onUploadMediaRequest(uploadedFile, result));
        yield put(onUploadMediaRequest(compressedFile, result));

        const uploadAction = yield take((action: AnyAction) => {
            if (
                !isActionIncluded(action, [
                    actions.ON_MEDIA_UPLOAD_SUCCESS,
                    actions.ON_MEDIA_UPLOAD_FAIL,
                    actions.ON_MEDIA_UPLOAD_CANCEL
                ])
            ) {
                return false;
            }
            return action.id === result.med_id;
        });

        if (uploadAction.type === actions.ON_MEDIA_UPLOAD_CANCEL) {
            yield put(skipAction);
            reject?.(skipAction);

            return;
        }

        if (uploadAction.type === actions.ON_MEDIA_UPLOAD_FAIL) {
            throw Error('generic_upload_error');
        }

        const successAction = { ...uploadMediaSuccess(newId), ...metadata };
        console.log(
            'THIS IS THE SUCCESS ACTION FOR UPLOAD_MEDIA_FUNCTION ---> ',
            successAction
        );
        yield put(successAction);

        resolve?.(newId);
    } catch (error) {
        const failAction = { ...uploadMediaFail(error as Error), ...metadata };
        yield put(failAction);
        // yield put(switchModeAsynchronous()); // turn protocol to asynchronous mode on error

        reject?.(error);
    }
}

function* onUploadFail(action: RT<typeof onUploadMediaFail>) {
    yield put(disableLoading(deleteMedia(action.id)));
}

function* onMediaUpload(action: ICreateMediaAction): SagaIterator {
    return yield race({
        result: call(postMediaRequest, action),
        cancel: take(
            (action: AnyAction) => action.type === actions.CANCEL_MEDIA_UPLOAD
        )
    });
}

function* onMediaObjectCreate(action: AnyAction) {
    const metadata = { localId: getActionId(action) };
    const uploadedFile = action.file;
    if (isServiceWorkerRunning()) {
        yield call(generatePreviews, uploadedFile, metadata.localId); // if this runs correctly, this should generate previews independant from the compression
    }
}

const createMediaEffect = takeEvery(
    actions.CREATE_MEDIA_REQUEST,
    onMediaUpload
);
const uploadMediaFailEffect = takeEvery(
    actions.ON_MEDIA_UPLOAD_FAIL,
    onUploadFail
);
const uploadMediaTransactionEffect = takeEvery(
    actions.UPLOAD_MEDIA_FUNCTION_REQUEST,
    uploadMediaTransaction
);

const createMediaObjectEffect = takeEvery(
    actions.CREATE_MEDIA_OBJECT,
    onMediaObjectCreate
);

// catch the new action that is made to the "uploads" reducer, and generate previews to it
// the generated previews will be matched with the localId

export default all([
    createMediaEffect,
    uploadMediaFailEffect,
    uploadMediaTransactionEffect,
    createMediaObjectEffect,
    call(queueMediaUpload)
]);
