// Note: causes issues with storybook, if the file is imported anywhere in storybook used components

import { store } from './store';

import history from './modules/history';
import { sessionStorageManager } from './modules/storage';

import {
    connectivtiyChangeOnline,
    connectivtiyChangeOffline,
    backgroundSyncStart,
    backgroundSyncFail,
    backgroundSyncSuccess,
    showSnackbar,
    connectionSpeedUpdate,
    asyncSyncUploadingProgress,
    hideUserOfflineNotification,
    offlineProtocolReadyToCheckin,
    setOfflineProtocolSyncingState,
    betaConnectivityOnline,
    betaConnectivityOffline
    // setNetworkRtt
} from './store/action-creators';

import { isLocalhost, isProdEnv } from './utils';

const swUrl = `${process.env.PUBLIC_URL}/sw.js`;

export function register() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        window.addEventListener('load', () => {
            if (isLocalhost) {
                // This is running on localhost. Let's check if a service worker still exists or not.
                checkValidServiceWorker(swUrl);

                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        'This web app is being served by a service worker.'
                    );
                });
            } else {
                // Is not localhost. Just register service worker
                registerValidSW(swUrl);
            }
        });
    }
}

export function unregister() {
    if (!('serviceWorker' in navigator)) return;

    navigator.serviceWorker.ready.then((registration) => {
        registration.unregister();
    });
}

// Note: only runs on localhost
function checkValidServiceWorker(swUrl: string) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get('content-type');
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf('javascript') === -1)
            ) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl);
            }
        })
        .catch(() => {
            attachWorkerEvents();
            console.log(
                'No internet connection. App is running in offline mode.'
            );
        });
}

class ActionManager {
    private buffer: AnyAction[];
    constructor() {
        this.buffer = [];
    }

    add(action: AnyAction) {
        this.buffer.unshift(action);
    }

    flush() {
        let action: AnyAction | undefined;

        // eslint-disable-next-line no-cond-assign
        while ((action = this.buffer.pop())) {
            if (!isProdEnv) {
                console.log('Dispatching from sw.js: ', action);
            }
            store.dispatch(action);
        }
    }

    clear() {
        this.buffer = [];
    }
}

const actionManager = new ActionManager();

if (!isProdEnv) console.info('running worker file');

function registerValidSW(swUrl: string) {
    if (!isProdEnv) console.info('sw register start');

    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            attachWorkerEvents();

            // Handle issues with outdated cached versions when dealing with urls
            const hasUpdated = sessionStorageManager.get('has-updated');

            if (hasUpdated === true) {
                sessionStorageManager.remove('has-updated');
                const url = sessionStorageManager.get('inital-url');

                if (typeof url === 'string') {
                    history.push({ pathname: url });
                }
            } else {
                sessionStorageManager.set(
                    'inital-url',
                    history.location.pathname
                );
            }

            if (!isProdEnv) console.info('attached sw events');

            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) return;

                installingWorker.onstatechange = () => {
                    if (installingWorker.state !== 'installed') return;

                    if (navigator.serviceWorker.controller) {
                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until each clinet reloads.

                        // Reload the page, neccessary to avoid issues with chunks

                        store.dispatch(
                            showSnackbar({
                                snackType: 'black',
                                message: 'app_update_message'
                            })
                        );

                        sessionStorageManager.set('has-updated', true);

                        console.info(
                            'Service worker updated. Reloading the page...'
                        );
                        window.location.reload();
                    }
                };
            };
        })
        .catch((error) => {
            console.error('Error during service worker registration: ', error);
        });
}

function attachWorkerEvents() {
    navigator.serviceWorker.addEventListener('message', (event) => {
        const { type, payload, valueType, tab } = event.data;

        if (!isProdEnv) {
            console.info('sw.js message: ', type, event);
        }

        switch (type) {
            case 'BLUR_TAB': {
                console.log('this tab will blur --> ', { tab });
                // window.blur();
                break;
            }
            case 'FOCUS_TAB': {
                console.log('this tab will focus --> ', { event, tab });
                window.blur();
                tab?.focus();
                setTimeout(window.focus, 1000);
                // window.focus();
                // window.alert('protocol is already open');
                break;
            }
            case 'SYNC_START': {
                store.dispatch(backgroundSyncStart());
                break;
            }
            case 'SYNC_SUCCESS': {
                store.dispatch(backgroundSyncSuccess());
                store.dispatch(hideUserOfflineNotification());
                // emit event to test
                store.dispatch(setOfflineProtocolSyncingState('SUCCESS'));
                store.dispatch(offlineProtocolReadyToCheckin());
                // const useBeta = store.getState().user.user.response?.use_beta
                actionManager.flush();
                break;
            }
            case 'SYNC_FAIL': {
                store.dispatch(backgroundSyncFail(payload));
                store.dispatch(setOfflineProtocolSyncingState('ERROR'));
                actionManager.flush();
                break;
            }
            case 'REQUEST_SYNC_UPDATE': {
                const { action } = event.data;

                actionManager.add(action);
                break;
            }
            case 'INFORM_NETWORK_SPEED': {
                store.dispatch(connectionSpeedUpdate(payload));
                break;
            }
            case 'CURRENT_QUEUE': {
                store.dispatch(
                    asyncSyncUploadingProgress({
                        uploadQueue: payload,
                        valueType
                    })
                );
                break;
            }
        }
    });
}

window.addEventListener('online', () => {
    store.dispatch(connectivtiyChangeOnline());
    store.dispatch(betaConnectivityOnline());
});

window.addEventListener('offline', () => {
    store.dispatch(connectivtiyChangeOffline());
    store.dispatch(betaConnectivityOffline());
});

// (navigator as any) && (navigator as any)?.connection?.addEventListener('change', ({target}: {target: {rtt: number}}) => {
//     const isInsideProtocol = window.location.pathname.includes('protocol');
//     if (isInsideProtocol) {
//         store.dispatch(setNetworkRtt(target.rtt));
//     }
// });
