import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { backDateFormat } from '../../../utils/formatter';

import { MenuSurfaceAnchor, MenuItem } from '@rmwc/menu';
import { ListDivider } from '@rmwc/list';
import { Ripple } from '@rmwc/ripple';

import StdDropdown from '../../atoms/StdDropdown';
import LanguageSelector from '../../misc/LanguageSelector';
import BetaSelector from '../../misc/BetaSelector';

import './support-fab.scss';

const SupportFab: React.FC = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const version: IVersion | null = useSelector(
        (state) => state.appState.version.response?.results?.[0]
    );
    const { ver_app, ver_app_date } = version || {};

    const daysAgo = ver_app_date
        ? moment().diff(moment(ver_app_date, backDateFormat), 'days')
        : null;
    const isOffline = useSelector((state) => state.global.isOffline);

    const isOutsideProtocol = !window.location.pathname.includes('protocol');
    const isInsideApp = !window.location.pathname.includes('accept_invite');

    return (
        <div className="support__container">
            <MenuSurfaceAnchor>
                <StdDropdown open={open} onClose={() => setOpen(false)}>
                    {!isOffline && (
                        <>
                            <a
                                className="std-blank-link"
                                href="http://support.aimmo.io"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <MenuItem>{t('support_guide')}</MenuItem>
                            </a>
                            <a
                                className="std-blank-link custom_intercom_launcher"
                                href="mailto:support@aimmo.io"
                            >
                                <MenuItem>{t('send_us_message')}</MenuItem>
                            </a>
                            <MenuItem disabled>
                                <LanguageSelector />
                            </MenuItem>
                            <ListDivider />
                        </>
                    )}
                    <MenuItem disabled>
                        <div>
                            <div>AIMMO {ver_app}</div>
                            {daysAgo != null && (
                                <div>
                                    {t('updated')} {daysAgo} {t('days_ago')}
                                </div>
                            )}
                        </div>
                    </MenuItem>
                    {isInsideApp && isOutsideProtocol && (
                        <>
                            <ListDivider />
                            <MenuItem disabled>
                                <BetaSelector />
                            </MenuItem>
                        </>
                    )}
                </StdDropdown>
                <Ripple>
                    <div
                        className="support__button font__header"
                        onClick={() => setOpen(true)}
                    >
                        ?
                    </div>
                </Ripple>
            </MenuSurfaceAnchor>
        </div>
    );
};

export default SupportFab;
