import { FFmpeg } from '@ffmpeg/ffmpeg';

const compressUploader = (useBeta = false, isBetaOffline = false) => {
    const ffmpeg = new FFmpeg();

    const loadFfmpeg = async () => {
        await ffmpeg.load();
    };

    const compressAndUpload = async (file: any) => {
        if (useBeta && isBetaOffline) {
            console.log('will not Compress and Upload ---> ', { file });
            return file;
        }

        console.log('starting Compress and Upload ---> ', { file });
        await loadFfmpeg();

        try {
            const compressedFile = await compressFile(file);
            console.log('FILE READY ----- ', compressedFile);
            return compressedFile;
        } catch (error) {
            console.error('Error uploading file:', error.message);
        }
    };

    const compressFile = async (file: any) => {
        const { name, type } = file;
        const data = new Uint8Array(await file.arrayBuffer());

        await ffmpeg.writeFile(name, data);

        let outputFileName;
        let compressionCommand;

        if (type.startsWith('video/')) {
            console.log('running compression for VIDEO');
            // Compression logic for videos
            outputFileName = 'output.mp4';
            compressionCommand = [
                '-i',
                name,
                '-c:v',
                'libx264',
                '-crf',
                '36',
                '-c:a',
                'aac',
                '-b:a',
                '128k',
                outputFileName
            ];
        } else if (type.startsWith('image/')) {
            console.log('running compression for IMAGE');
            // Compression logic for images
            outputFileName = 'output.jpg';
            compressionCommand = ['-i', name, '-q:v', '23', outputFileName];
        } else {
            throw new Error('Unsupported file type');
        }

        await ffmpeg.exec(compressionCommand);

        const result = await ffmpeg.readFile(outputFileName);

        const compressedFile = new File([result], name, { type });

        return compressedFile;
    };

    return { compressAndUpload };
};

export default compressUploader;
